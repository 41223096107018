import React from "react"
import SwiperCore, { Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Icon } from "../../Icon/Icon"
import { Wrapper, Prev, Next } from "./HeroCarouselStyles"
import { withHeroCarousel } from "./withHeroCarousel"
import { HeroCard } from "../Hero/Card/HeroCard"

SwiperCore.use([Autoplay])

export const HeroCarousel = withHeroCarousel(
  ({ items, ratio, autoplayDelay }): JSX.Element => {
    return (
      <Wrapper>
        <Swiper
          onSwiper={swiper => swiper.loopDestroy()}
          autoplay={{ delay: autoplayDelay }}
          loop={true}
          navigation={{
            prevEl: "#hero-prev",
            nextEl: "#hero-next",
          }}
          slidesPerView={1}
          breakpoints={{
            1024: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          }}          
        >
          {items?.map(card => (
            <SwiperSlide key={card?._key}>
              <HeroCard card={card?.image} ratio={ratio} />
            </SwiperSlide>
          ))}
        </Swiper>

        <Prev id={"hero-prev"} title={"previous image"}>
          <Icon
            name={"chevronLeft"}
            width={16}
            height={22}
            title={`Arrow left`}
          />
        </Prev>
        <Next id={"hero-next"} title={"next image"}>
          <Icon
            name={"chevronRight"}
            width={16}
            height={22}
            title={`Arrow right`}
          />
        </Next>
      </Wrapper>
    )
  },
)

export default HeroCarousel
